<template>
  <div class="card card-bordered">
      <div class="card-inner">
          <a-breadcrumb style="padding-bottom: 10px;">
            <router-link :to="{name: 'reports'}"><a-breadcrumb-item>Back To Reports</a-breadcrumb-item></router-link>
            <a-breadcrumb-item>SLA Adherence</a-breadcrumb-item>
          </a-breadcrumb>
          <div class="row">
            <div class="col-3">
                    <a-select show-search allowClear option-filter-prop="children" @change="getReport()" v-model="widgetFetcher.accountId" placeholder="Please Select An Account" style="width: 100%">
                        <a-select-option v-for="a in accounts" :key="a.id">
                            {{ a.text}}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="col-9">
                    <div class="float-right">
                        <div class="btn-group" style="margin-right: 15px;">
                            <button @click="setMonth('thisMonth')" type="button" class="btn btn-primary btn-sm">This Month</button>
                            <button @click="setMonth('lastMonth')" class="btn btn-dark btn-sm">Last Month</button>
                            <button @click="setMonth('monthBeforeLast')" class="btn btn-dark btn-sm">Month <em style="font-size:10px;" class="icon ni ni-caret-left-fill"></em> Last</button>
                            <button @click="setMonth('thisYear')" class="btn btn-dark btn-sm">This Year</button>
                        </div>
                       <a-month-picker @change="getReport()" v-model="widgetFetcher.date" format="MM/YYYY" placeholder="Month" style="margin-right: 10px;"/>
                    </div>
                </div>
            </div>
            <hr class="preview-hr">
            <div class="row">
                <div class="col-6" v-if="summary">
                    <ticketsslaadherencechart :needsRefresh="needsRefresh" :chartData="summary"/>
                </div>
                <div class="col-6" v-if="summary">
                    <div class="row">
                        <div class="col-6">
                            <div class="card stat">
                                <div class="card-inner">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-title">
                                                <h5 class="title text-center">Total Tickets Completed</h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="card-title">
                                                    <h4 class="text-center">{{ summary.ticketCount || 0 }}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="card stat">
                                <div class="card-inner">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-title">
                                                <h5 class="title text-center">Tickets Inside SLA</h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="card-title">
                                                    <h4 class="text-center">{{ summary.ticketInsideSLACount || 0 }}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="card stat">
                                <div class="card-inner">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-title">
                                                <h5 class="title text-center">Tickets Outside SLA</h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="card-title">
                                                    <h4 class="text-center">{{ summary.ticketOutsideSLACount || 0}}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-6">
                            <div class="card stat">
                                <div class="card-inner">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-title">
                                                <h5 class="title text-center">???????????</h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="card-title">
                                                    <h4 class="text-center">{{ summary.ticketCount || 0 }}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div> 
                </div>
            </div>
            <hr class="preview-hr">
            <div class="col-12"><h5>Tickets Outside SLA</h5></div>
            <div class="col-12" v-if="tickets">
                <vue-good-table v-if="tickets"
                    :columns="columns"
                    :rows="tickets"
                    styleClass="vgt-table condensed tblhov"
                    :search-options="{ enabled: true, placeholder: 'Search Tickets'}"
                    :sort-options="{ enabled: true,}"
                    @on-cell-click="showTicket"
                >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'createdDate'">{{ $moment(props.row.createdDate).format("H:mm DD/MM/YY") }}</span>
                </template>
                </vue-good-table>
            </div>
      </div>
    <a-drawer :width="600"
      placement="right"
      :closable="false"
      @close="closeTicket()"
      :visible="showTicketData">
      <ticket :ticket="selectedTicket" />
    </a-drawer>
  </div>
</template>

<script>
import ticket from '@/components/desk/tickets/ticket.vue'
import ticketsslaadherencechart from '@/components/widgets/desk/ticketsslaadherencechart.vue'

export default {
    components: { ticket, ticketsslaadherencechart },
    data() {
        return {
            needsRefresh: null,
            accounts: [],
            widgetFetcher: { 
                start: this.$moment().startOf('month'),
                end: this.$moment().endOf('month'),
            },
            selectedMonth: 'thisMonth',
            tickets: [],
            summary: {},
            ticketCounts: null,
            showTicketData: false,
            selectedTicket: {},
            columns: [{
              label: 'Account',
              align: 'start',
              sortable: false,
              field: 'accountName',
            }, {
              label: 'title',
              field: 'title'
            },  {
              label: 'details',
              field: 'details'
            }, {
              label: 'SLA Minutes Missed By',
              field: 'slaMinutesMissedBy'
            }, {
              label: 'createdDate',
              field: 'createdDate'
            }],
        }
    },
    created() {
        this.getView()
       this.widgetFetcher.date = this.$moment().startOf('month')
        this.getLists()
    },
    methods: {
        getView (){ this.$http.get('/ViewAccess/slaAdherence')
        .then(() => { 
        })
        .catch(() => { 
        })},
        showTicket(params) {
          this.selectedTicket = params.row
          this.showTicketData = true
        },
        getLists(){
           this.$http.get('/lists/Get_List/accounts')
            .then((response) => {
                this.accounts = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
          
        },
        getReport(){
          this.needsRefresh = false
          if (this.widgetFetcher.accountId) {
            this.$http.post('/reports/Get_Account_SLA_Adherence/', this.widgetFetcher)
            .then((response) => {
                this.summary = response.data.slaAdherenceSummary
                this.tickets = response.data.tickets

                this.needsRefresh = true
            })
            .catch(() => {
              this.$message.error('There has been an error')
            })
          }
          //this.$http.post('/reports/Get_TicketsByUserCounts/', this.widgetFetcher)
          //.then((response) => {
          //    this.ticketCounts = response.data
          //})
          //.catch(() => {
          //  this.$message.error('There has been an error')
          //})
          this.initiateRefresh()
        },
        closeTicket(){
          this.selectedTicket = null
          this.showTicketData = false
        },
        setMonth(s){
            if (s === 'thisMonth') {
                this.widgetFetcher.date = this.$moment().startOf('month')
            } else if (s === 'lastMonth') {
                this.widgetFetcher.date = this.$moment().subtract(1, 'months').startOf('month')
            } else if (s === 'monthBeforeLast') {
                this.widgetFetcher.date = this.$moment().subtract(2, 'months').startOf('month')
            } else if (s === 'thisYear') {
                this.widgetFetcher.date = this.$moment().subtract(2, 'months').startOf('month')
            }
            this.getReport()
        },
        initiateRefresh(){
            if (this.needsRefresh === true) {
                this.needsRefresh = false
            } else {
                this.needsRefresh = true
            }
        }
    }
}
</script>

<style scoped>
  .stat{
    height: 165px
  }
</style>