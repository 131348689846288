<template>
    <div class="card">
        <div v-if="!chartData && chartData.ticketInsideSLACount && chartData.ticketOutsideSLACount" class="card-inner">
          <p>There is no data for this range</p>
        </div>
        <div v-if="chartData" class="card-inner">
            <div class="card-title-group align-start mb-2">
                <div class="card-title">
                    <h6 class="title">Tickets SLA Inside/Outside</h6>
                </div>
            </div>
            <div v-if="loaded">
              <apexchart :options="chartOptions" :series="series"></apexchart>
            </div>
        </div>
    </div><!-- .card -->
</template>

<script>
export default {
    props: {
        chartData: {
            type: Object
        },
        needsRefresh: {
          type: Boolean
        }
    },
    watch : {
        needsRefresh:function() {
          this.parseChartData()
        },
        chartData:function() {
          this.parseChartData()
        },
    },
    data() {
        return {
          ticketStats: null,
          loaded: false,
          series: [{
          name: "Inside SLA",
          data: []
      },{
          name: "Outside SLA",
          data: []
      }],
          chartOptions: {
            colors: ['#1EE0AC','#FF4560'],
            fill: {
              colors: ['#1EE0AC','#FF4560']
            },
            dataLabels: {
              enabled: false,
              style: {
                colors: ['#1EE0AC','#FF4560']
              }
            },
            markers: {
              colors: ['#1EE0AC','#FF4560']
            },
            chart: {
              width: 200,
              height: 250,
              type: 'bar'
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              categories: ['Tickets SLA Adherence']
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm' + 'Of the Month'
              },
            },
          },
        }
    },
    mounted() {
        this.parseChartData()
    },
    methods:{
        parseChartData() {  
          this.loaded = false
          if (this.chartData) { 
                this.series[0].data  = []
                this.series[1].data  = []
                if (this.chartData && this.chartData.ticketInsideSLACount != null && this.chartData.ticketOutsideSLACount != null)
                {
                  this.series[0].data.push(this.chartData.ticketInsideSLACount)
                  this.series[1].data.push(this.chartData.ticketOutsideSLACount) 
                } else 
                {
                  this.series[0].data.push(0)
                  this.series[1].data.push(0) 
                } 
            
            }
            this.$nextTick(() => {
                this.loaded = true
              })
        }
    }
}
</script>

<style>

</style>